<template>
  <div>
    <v-container>
      <v-row>


        <v-col cols="12" sm="8" md="8">

          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('academicOffice')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
                v-for="(d,i) in infoList"
                :key="i"
                dark
                large
            >
              <template v-slot:icon>
                <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                  <img v-if="d.url" :src="d.url">
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
              </template>
              <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
                <v-card-title class="text-h5">
                  <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

                </v-card-title>
                <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px;">{{ $t(d.name) }}</span>

                <v-divider inset style="color: green"></v-divider>

                <v-card-text style="text-align: left">
                  <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                  <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                  <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                  <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                  <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                  <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

export default {
  name: 'AcademicOffice',
  components: {Media,SideBarEvent},
  data(){
    return{
        infoList:[
          {
            name:"sok_sokhan",
            position:"chief",
            phoneNumber:"+855-86 879 292",
            bachelor:"agriculture_science",
            master:"",
            phd:"",
            email:"soksokhan@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F%E1%9E%9F%E1%9E%BB%E1%9E%81%20%E1%9E%9F%E1%9E%BB%E1%9E%81%E1%9E%93%20-%20rtc%20aao.jpg?alt=media&token=2546ccd1-ad9d-49d8-b677-d928b4b023e2"
          },
          {
            name:"leng_channy",
            position:"deputyChief",
            phoneNumber:"+855-77 420 492",
            associate:"english",
            bachelor:"agronomy",
            master:"natural_resources_management",
            phd:"",
            email:"lengchanny@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG_20210901_220034_527%20-%20LENG%20Channy.jpg?alt=media&token=6f208e98-8f33-40de-b0d6-e15ac70fd053"
          },
          {
            name:"khiev_rathane",
            position:"deputyChief",
            phoneNumber:"+855-17 38 78 65",
            bachelor:"it",
            master:"",
            phd:"",
            email:"khievrathane967@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FDSC_8907%20copy%20-%20Khiev%20Rathane.jpg?alt=media&token=344fe39b-d2d4-4838-8481-4845594af888"
          },
          {
            name:"kung_chantha",
            position:"technicalTeacher",
            phoneNumber:"+855-93 979 535",
            bachelor:"khmer_literature",
            master:"",
            phd:"",
            email:"chanthakung8@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2021-03-29_10-43-50%20-%20Kung%20Chantha.jpg?alt=media&token=3fbf2f68-a5e2-4dc8-9cbd-6cdf2f5f6741"
          },
          {
            name:"sim_sophea",
            position:"technicalTeacher",
            phoneNumber:"+855-98 678 889",
            bachelor:"electrical_engineering",
            master:"",
            phd:"",
            email:"sophea.sim6@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2021-09-01_15-12-25%20-%20sim%20sophea.jpg?alt=media&token=7c78b0db-168c-454c-9c9f-ed33836bf967"
          },
          {
            name:"may_chanyaran",
            position:"technicalTeacher",
            phoneNumber:"+855-98 808 286",
            bachelor:"agronomy",
            master:"",
            phd:"",
            email:"mchanyaran@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2021-09-01_15-15-01%20-%20rtc%20aao.jpg?alt=media&token=87f12263-6f4a-4582-a4ba-93ec4a582728"
          },
          {
            name:"pech_dara",
            position:"technicalTeacher",
            phoneNumber:"+855-70 942 471",
            bachelor:"civil_engineering",
            master:"",
            phd:"",
            email:"pechdara6@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FDSC_2656%20copy%20-%20pech%20dara.jpg?alt=media&token=26ebbbe6-3d17-479f-b7b3-006a6ee332c7"
          },
          {
            name:"sao_narong",
            position:"technicalTeacher",
            phoneNumber:"+855-93 623 636",
            bachelor:"it",
            master:"",
            phd:"",
            email:"narongsao98@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2021-09-10_16-20-40.jpg?alt=media&token=c2cc4d9a-9d93-45b3-81bd-3aa6c7adcc70"
          },
          {
            name:"sambath_sokhak",
            position:"technicalTeacher",
            phoneNumber:"+855-96 306 8871",
            bachelor:"accounting_Finance",
            master:"",
            phd:"",
            email:"sokhakmoon@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG_20210405_174127_424%20-%20SAMBATH%20Sokhak.jpg?alt=media&token=b06accc9-490e-4f79-9412-e554b2dd0d91"
          },
          {
            name:"phai_mithona",
            position:"technicalTeacher",
            phoneNumber:"+855-86 779 335",
            bachelor:"electricity",
            master:"",
            phd:"",
            email:"Phai.mithona2021@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F75B0C3CE-6C94-406D-9A10-089DEEA221C9%20-%20Mithona%20Phai.jpeg?alt=media&token=fa3ba7cc-1708-4139-b1da-969c54bd7610"
          },
          {
            name:"krol_noeurn",
            position:"technicalTeacher",
            phoneNumber:"+855-15 540 063",
            bachelor:"computer_science",
            master:"",
            phd:"",
            email:"noeurnkrol@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2019-09-05_08-37-53%20-%20Noeurn%20Krol.jpg?alt=media&token=ca6b8ef2-9f0d-4586-89a3-19430460a339"
          },


        ]

    }
  }
}
</script>
